import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
    useCallback,
} from 'react';
import './AddInsightDialog.scss';
import {
    Button,
    ItemSelectorField,
    NumberField,
    TextField,
} from '../UIElements';
import { Eye } from 'react-feather';
import { commentsServices } from '../../services';
import {
    UpsertDynamicValueData,
    UpsertInsightSelectOption,
} from '~/services/commentsServices';
import { toast } from 'react-hot-toast';
import CheckboxField from '../UIElements/CheckboxField';
import { useEntityContext, useTemporaryErrorDisplay } from '../../hooks';
import { ProjectContext } from '../../context/project';
import {
    ImageViewerProviderInterface,
    ProjectProviderInterface,
} from '~/interfaces/contexts';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { ImageViewerContext } from '~/context/imageViewer';
import dataURItoBlob from '~/utils/dataURItoBlob';
import {
    CGDocumentInternalMetadata,
    DynamicPropertyInterfaceDetailed,
    HighlightArea,
} from '~/interfaces/entities';
import { IntlShape } from 'react-intl';
import { DYNAMIC_PROPERTY_TYPES } from '~/constants/DynamicPropertyTypes.enum';
import {
    getLastSelectedTopic,
    updateLastSelectedTopic,
} from '~/helpers/getLastSelectedTopic';
import { TopicsProviderInterface } from '~/modules/Topics/types/TopicsContext.interface';
import { TopicsContext } from '~/context/topics';
import { TopicInterface } from '~/modules/Topics/types/Topic.interface';
import TopicSelectorMenu from '~/modules/Topics/components/TopicSelectorMenu';
import { ColumnValueOption } from '~/interfaces/columnValues/ColumnFilterContext.interface';

interface InsightCreation {
    topic: null | TopicInterface;
}
interface AddInsightDialogInterface {
    intl: IntlShape;
    textSelection?: string;
    document: CGDocumentInternalMetadata;
    page: number;
    callback: () => void;
    onLoadFields?: () => void;
    highlightAreas?: HighlightArea[];
    image?: string; // base64
    showTitle?: boolean;
    classNameBody?: string;
}

type DynamicValueCreation = {
    id: string; // columnId
} & (
    | {
          type:
              | DYNAMIC_PROPERTY_TYPES.SELECT
              | DYNAMIC_PROPERTY_TYPES.MULTI_SELECT;
          value: ColumnValueOption[]; // label is select option name
      }
    | {
          type: DYNAMIC_PROPERTY_TYPES.TEXT;
          value?: string;
      }
    | {
          type: DYNAMIC_PROPERTY_TYPES.NUMBER;
          value?: number;
      }
    | {
          type: DYNAMIC_PROPERTY_TYPES.BOOLEAN;
          value?: boolean;
      }
);

const AddInsightDialog = ({
    intl,
    textSelection,
    document,
    page,
    callback,
    highlightAreas,
    image,
    showTitle,
    onLoadFields,
    classNameBody,
}: AddInsightDialogInterface) => {
    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const { topics, pinedTopic } =
        useEntityContext<TopicsProviderInterface>(TopicsContext);

    const { handleError, error } = useTemporaryErrorDisplay();

    const [loading, setLoading] = useState(false);
    const [insight, setInsight] = useState<InsightCreation>({
        topic: null,
    });

    const [columnsMetadata, setColumnsMetadata] = useState<
        DynamicPropertyInterfaceDetailed[]
    >([]);

    const [additionalProperties, setAdditionalProperties] = useState<
        DynamicValueCreation[]
    >([]);

    const { handleImage } =
        useEntityContext<ImageViewerProviderInterface>(ImageViewerContext);

    const formRef = useRef(null);

    useEffect(() => {
        getTableColumns();
        preselectTopic();
    }, []);

    // check if the user has already selected a topic in the past (stored in localstorage) or has a pinned topic, use one of them if exist.
    const preselectTopic = () => {
        const lastSelected = getLastSelectedTopic();

        if (lastSelected) {
            const topic = topics.find((t) => t.id === lastSelected);

            if (topic) {
                setInsight({
                    ...insight,
                    topic,
                });
                return;
            }
        }

        if (pinedTopic) {
            setInsight({
                ...insight,
                topic: pinedTopic,
            });
        }
    };

    const getTableColumns = async () => {
        setLoading(true);
        const [error, response] = await commentsServices.getCommentColumns(
            selectedProject?.id as string
        );
        setLoading(false);

        onLoadFields && onLoadFields();
        if (error) return;
        const { data } = response;
        setColumnsMetadata(data);
    };

    const handleSubmit = async (
        e:
            | React.FormEvent<HTMLFormElement>
            | React.MouseEvent<HTMLButtonElement>
    ) => {
        e.preventDefault();

        if (!insight.topic) {
            handleError(intl.formatMessage({ id: 'please_select_topic' }));
            return;
        }

        setLoading(true);

        // comment to be highlighted in the SmartViewver
        const text: string | undefined = textSelection
            ? textSelection.replace(/  +/g, ' ')
            : undefined; // remove duplicated spaces

        // rename 'pageIndex' key provided by the viewer to 'page' to be valid for the backend
        let highlights: HighlightArea[];
        if (highlightAreas) {
            highlights = highlightAreas.map((h) => {
                let page;
                if (Number.isInteger(h?.pageIndex)) page = h.pageIndex;
                else if (Number.isInteger(h?.page)) page = h.page;
                return {
                    height: h.height,
                    left: h.left,
                    page,
                    top: h.top,
                    width: h.width,
                };
            });
        } else {
            highlights = [];
        }

        const payload = {
            document_id: document.id,
            topic_id: insight.topic.id,
            page_number: Number(page),
            text: text || '',
            highlight_areas: highlights.length ? highlights : undefined,
        };

        // create dynamic values
        let dynamicValues: UpsertDynamicValueData[] | undefined;
        if (additionalProperties.length > 0) {
            dynamicValues = additionalProperties.map((value) => {
                let payload: UpsertDynamicValueData;

                if (
                    value.type === DYNAMIC_PROPERTY_TYPES.SELECT ||
                    value.type === DYNAMIC_PROPERTY_TYPES.MULTI_SELECT
                ) {
                    const selectOptions: UpsertInsightSelectOption[] =
                        value.value.map((selectOption) => {
                            if ('isNew' in selectOption && selectOption.isNew) {
                                return {
                                    isNew: selectOption.isNew,
                                    comment_dynamic_property_id: value.id,
                                    name: selectOption.label,
                                };
                            } else {
                                return {
                                    isNew: false,
                                    comment_dynamic_property_id: value.id,
                                    name: selectOption.label,
                                    id: selectOption.value,
                                };
                            }
                        });
                    payload = {
                        comment_dynamic_property_id: value.id,
                        dynamic_property_type: value.type,
                        selectOptions: selectOptions,
                    };
                } else {
                    const valuePayload = {
                        value_number:
                            value.type === DYNAMIC_PROPERTY_TYPES.NUMBER
                                ? value.value
                                : null,
                        value_text:
                            value.type === DYNAMIC_PROPERTY_TYPES.TEXT
                                ? value.value
                                : null,
                        value_boolean:
                            value.type === DYNAMIC_PROPERTY_TYPES.BOOLEAN
                                ? value.value
                                : null,
                    };

                    payload = {
                        comment_dynamic_property_id: value.id,
                        dynamic_property_type: value.type,
                        ...valuePayload,
                    };
                }

                return payload;
            });
        }

        const createdInsightId =
            await commentsServices.upsertCommentAndCreateDeps({
                commentDTO: payload,
                image: image,
                dynamicValueData: dynamicValues,
            });

        setLoading(false);

        if (!createdInsightId) {
            toast.error(intl.formatMessage({ id: 'general_error' }));
            return;
        }

        toast.success(
            intl.formatMessage({
                id: 'insight_created_successfully',
            })
        );
        callback && callback();
    };

    const onOpenImage = () => {
        if (image)
            handleImage({
                blob: dataURItoBlob(image),
                options: { hideDownload: true },
            });
    };

    const handleAdditionalProperty = (colId, value, colType) => {
        const idx = additionalProperties.findIndex((c) => c.id === colId);

        if (idx === -1) {
            const payload = {
                id: colId,
                value,
                type: colType,
            };
            setAdditionalProperties([...additionalProperties, payload]);
        } else {
            const prev = [...additionalProperties];
            prev[idx].value = value;
            setAdditionalProperties(prev);
        }
    };

    // order the columns based on the position property
    const orderedColumns = useMemo(() => {
        const orderedColumns = [...columnsMetadata].sort(
            (a, b) => a.position - b.position
        );

        return orderedColumns;
    }, [columnsMetadata]);

    const onChangeDynamicItems = (items, colId, colType) => {
        const idx = additionalProperties.findIndex((c) => c.id === colId);

        if (idx === -1) {
            const payload = {
                id: colId,
                value: items,
                type: colType,
            };
            setAdditionalProperties([...additionalProperties, payload]);
        } else {
            if (!items.length)
                return setAdditionalProperties(
                    additionalProperties.filter((c) => c.id !== colId)
                );

            const prev = [...additionalProperties];
            prev[idx].value = items.length ? items : [];
            setAdditionalProperties(prev);
        }
    };

    const getColumnField = useCallback(
        (col) => {
            const fieldValue =
                additionalProperties.find((c) => c.id === col.id)?.value || '';

            switch (col.type) {
                case DYNAMIC_PROPERTY_TYPES.TEXT:
                    return (
                        <TextField
                            key={col.id}
                            label={col.name}
                            inputProps={{
                                fieldValue,
                                onChange: (e) =>
                                    handleAdditionalProperty(
                                        col.id,
                                        e.target.value,
                                        col.type
                                    ),
                            }}
                        />
                    );

                case DYNAMIC_PROPERTY_TYPES.NUMBER:
                    return (
                        <NumberField
                            key={col.id}
                            label={col.name}
                            inputProps={{
                                value: fieldValue || undefined,
                                onChange: ({ value }) => {
                                    if (value === null) return;
                                    handleAdditionalProperty(
                                        col.id,
                                        value,
                                        col.type
                                    );
                                },
                            }}
                        />
                    );

                case DYNAMIC_PROPERTY_TYPES.BOOLEAN:
                    return (
                        <CheckboxField
                            key={col.id}
                            label={col.name}
                            inputProps={{
                                checked: fieldValue || false,
                                onChange: (e) =>
                                    handleAdditionalProperty(
                                        col.id,
                                        e.target.checked,
                                        col.type
                                    ),
                            }}
                            id={col.id}
                            className="ml-1"
                        />
                    );

                case DYNAMIC_PROPERTY_TYPES.MULTI_SELECT:
                    return (
                        <ItemSelectorField
                            key={col.id}
                            intl={intl}
                            onChange={(items) =>
                                onChangeDynamicItems(items, col.id, col.type)
                            }
                            value={
                                additionalProperties.find(
                                    (p) => p.id === col.id
                                )?.value || []
                            }
                            options={col.comment_select_options}
                            colName={col.name}
                            singleItem={false}
                        />
                    );
                case DYNAMIC_PROPERTY_TYPES.SELECT:
                    return (
                        <ItemSelectorField
                            key={col.id}
                            intl={intl}
                            onChange={(items) =>
                                onChangeDynamicItems(items, col.id, col.type)
                            }
                            value={
                                additionalProperties.find(
                                    (p) => p.id === col.id
                                )?.value || []
                            }
                            options={col.comment_select_options}
                            colName={col.name}
                            singleItem={true}
                        />
                    );
            }
        },
        [additionalProperties, handleAdditionalProperty]
    );

    const onSelectTopic = (topic: TopicInterface | null) => {
        // save the selection in localstorage to be used in the next time
        if (topic) updateLastSelectedTopic(topic.id);

        setInsight({
            ...insight,
            topic,
        });
    };

    const imgRef = useRef(null);

    const imgStyle = useMemo(() => {
        if (!imgRef.current) return {};

        if (imgRef.current.height > 150) {
            return {
                transform: `translateY(-${(imgRef.current.height - 150) / 2}px)`,
            };
        }
    }, [imgRef.current]);

    return (
        <div className="add-insight-dialog">
            {showTitle && (
                <div className="add-insight-dialog__header">
                    <h4>
                        {intl.formatMessage({
                            id: 'collect_insight',
                        })}
                    </h4>
                </div>
            )}

            <form
                className={`add-insight-dialog__body ${classNameBody}`}
                onSubmit={handleSubmit}
                ref={formRef}
            >
                {image && (
                    <div>
                        <div className="add-insight-dialog__capture">
                            <label>
                                {intl.formatMessage({ id: 'capture' })}
                            </label>
                            <div
                                className="add-insight-dialog__capture-container"
                                onClick={onOpenImage}
                            >
                                <TransformWrapper disabled>
                                    <TransformComponent>
                                        <img
                                            className="add-insight-dialog__image"
                                            ref={imgRef}
                                            src={image}
                                            style={imgStyle}
                                            alt="snippet-image"
                                        />
                                    </TransformComponent>
                                </TransformWrapper>
                                <div className="overlay">
                                    <a
                                        href="#"
                                        title={intl.formatMessage({
                                            id: 'view_full_image',
                                        })}
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                        className="icon"
                                    >
                                        <Eye />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="add-insight-dialog__text-hint">
                            <small>
                                {document.name} -{' '}
                                {intl.formatMessage({ id: 'page' })} {page}
                            </small>
                        </div>
                    </div>
                )}

                <TopicSelectorMenu
                    intl={intl}
                    onSelectTopic={onSelectTopic}
                    selectedTopic={insight.topic}
                    error={error}
                    canCreate
                    label={intl.formatMessage({ id: 'topic' })}
                    placeholder={
                        intl.formatMessage({ id: 'search_or_create_topics' }) +
                        '...'
                    }
                    topicMenuSize="small"
                />

                {/* Display custom columns created by the users */}
                {orderedColumns.map(getColumnField)}
            </form>
            <div className="add-insight-dialog__footer">
                <Button
                    variant="accent"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {intl.formatMessage({
                        id: 'collect',
                    })}
                </Button>
            </div>
        </div>
    );
};

export default AddInsightDialog;
