import { useMemo, useState } from 'react';
import { Tag } from 'react-feather';
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import RestrictedUI from '~/components/RestrictedUI';
import { BasePopover, Button, TagsPicker } from '~/components/UIElements';
import { ENTITIES } from '~/constants/entities';
import { PERMISSIONS } from '~/constants/memberPermissions';
import { ProjectContext } from '~/context/project';
import { TagsContext } from '~/context/tags';
import { useEntityContext, useFloatMenu } from '~/hooks';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import { InsightInterfaceDetailed } from '~/interfaces/entities';
import { TagsProviderInterface } from '~/modules/Tags/types/TagsContext.interface';
import { documentServices, tagsServices } from '~/services';
import { LabelValueOptionInterface } from '~/types/labelValueOption.interface';

interface TagInsightsButtonProps {
    selectedItems: string[];
    insights: InsightInterfaceDetailed[];
    nonPaginatedDocumentIds: string[];
    disabled?: boolean;
}

const TagInsightsButton = ({
    selectedItems,
    insights,
    nonPaginatedDocumentIds,
    disabled,
}: TagInsightsButtonProps) => {
    const tagsMenu = useFloatMenu();
    const { tagOptions, getTags, transformedFolderStructure } =
        useEntityContext<TagsProviderInterface>(TagsContext);

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const [loading, setLoading] = useState(false);

    const intl = useIntl();

    const allItemsAreSelected = selectedItems.length > insights.length;

    const getCommonDocumentTags = (): {
        options: LabelValueOptionInterface[];
        ids: string[];
    } => {
        if (!tagOptions.length || allItemsAreSelected)
            return {
                options: [],
                ids: [],
            };

        const items = insights.filter((insight) =>
            selectedItems.includes(insight.id)
        );

        const documentTags = items.map(
            (insight) => new Set(insight.document_tags.map((t) => t.id))
        );

        let commonTags = Array.from(documentTags[0]);
        for (const tag of documentTags) {
            commonTags = commonTags.filter((t) => tag.has(t));
        }

        return {
            options: tagOptions.filter((t) => commonTags.includes(t.value)),
            ids: commonTags,
        };
    };

    const commonDocumentTags = getCommonDocumentTags();

    const addTagsToFile = async (newTags: LabelValueOptionInterface[]) => {
        setLoading(true);

        const tagsToAdd: string[] = newTags
            .filter((t) => !t.isNew)
            .map((t) => t.value);

        const tagsToCreate = newTags.filter((t) => t.isNew).map((t) => t.value);

        const tagsToRemove: string[] = commonDocumentTags.ids.filter(
            (t) => !tagsToAdd.includes(t)
        );

        if (tagsToCreate.length) {
            const tagPromises = tagsToCreate.map((t) =>
                tagsServices.createTag({
                    project_id: selectedProject?.id as string,
                    name: t,
                    tag_group_id: null,
                })
            );

            const response = await Promise.all(tagPromises);

            response.forEach(
                ([error, response]) =>
                    !error && tagsToAdd.push(response.data.id)
            );
        }

        let docIds: string[] = [];

        if (allItemsAreSelected) {
            docIds = nonPaginatedDocumentIds;
        } else {
            docIds = insights
                .filter((s) => selectedItems.includes(s.id))
                .map((s) => s.document_id);
        }
        const uniqueIds = [...new Set(docIds)];

        const payload = {
            document_ids: uniqueIds,
            add_tag_ids: tagsToAdd,
            remove_tag_ids: tagsToRemove || [],
        };

        const [error] = await documentServices.updateDocumentsTags(payload);

        setLoading(false);

        if (error) {
            toast.error(intl.formatMessage({ id: 'general_error' }));
            return;
        }

        toast.success(intl.formatMessage({ id: 'tags_updated_successfully' }));

        getTags();
        tagsMenu.handleMenu();
    };

    return (
        <RestrictedUI to={PERMISSIONS.EDIT} entity={ENTITIES.INSIGHTS}>
            <BasePopover
                isOpen={tagsMenu.showMenu}
                positions={['bottom', 'left', 'right']}
                align="start"
                onClickOutside={() => tagsMenu.setShowMenu(false)}
                content={
                    <TagsPicker
                        onApply={addTagsToFile}
                        tags={tagOptions}
                        selectedTags={commonDocumentTags.options}
                        loading={loading}
                        folderStructure={transformedFolderStructure}
                    />
                }
            >
                <Button
                    variant="secondary"
                    onClick={tagsMenu.handleMenu}
                    iconBefore={Tag}
                    disabled={disabled || loading}
                >
                    <span>
                        {intl.formatMessage({
                            id:
                                selectedItems.length > 1
                                    ? 'tags_files'
                                    : 'tags_file',
                        })}
                    </span>
                </Button>
            </BasePopover>
        </RestrictedUI>
    );
};

export default TagInsightsButton;
