import { useEntityContext, useFloatMenu, useOpenFolders } from '~/hooks';
import styles from './InsightsTableHeader.module.scss';
import { ProjectContext } from '~/context/project';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import toast from 'react-hot-toast';
import { commentsServices } from '~/services';
import { useIntl } from 'react-intl';
import RestrictedUI from '~/components/RestrictedUI';
import { PERMISSIONS } from '~/constants/memberPermissions';
import { ENTITIES } from '~/constants/entities';
import {
    BaseModal,
    Button,
    DeleteConfirmationDialog,
    FolderSelector,
    IconButton,
} from '~/components/UIElements';
import { Download, Link, Trash2 } from 'react-feather';
import CaretBoxIcon from '~/assets/icons/shared/CaretBoxIcon';
import { TopicsProviderInterface } from '~/modules/Topics/types/TopicsContext.interface';
import { TopicsContext } from '~/context/topics';
import { InsightInterfaceDetailed } from '~/interfaces/entities';
import { generateInsightTopicFilteredLink } from '~/utils/generateLinks';
import useWorkspaces from '~/modules/Workspaces/hooks/useWorkspaces';
import TagInsightsButton from '~/modules/Insights/components/InsightsTable/InsightsTableHeader/TagInsightsButton';
import { getErrorCode } from '~/utils/getErrorCode';

interface InsightsTableHeaderProps {
    insights: InsightInterfaceDetailed[];
    showActions: boolean;
    nonPaginatedIds?: string[];
    nonPaginatedDocumentIds?: string[];
    selectedItems: string[];
    loading: boolean;
    handleLoading: (loading: boolean) => void;
    onDownload: () => void;
    onUpdate: () => void;
    handleSelection: (items: string | string[]) => void;
}

const InsightsTableHeader = ({
    insights,
    showActions,
    selectedItems,
    loading,
    nonPaginatedIds,
    nonPaginatedDocumentIds,
    handleSelection,
    onDownload,
    handleLoading,
    onUpdate,
}: InsightsTableHeaderProps) => {
    const intl = useIntl();

    const selectedAllVisible =
        selectedItems.length !== nonPaginatedIds?.length &&
        selectedItems.length === insights.length;

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const { filledFolderTree, getTopics } =
        useEntityContext<TopicsProviderInterface>(TopicsContext);

    const { selectedWorkspace } = useWorkspaces();

    const deleteMenu = useFloatMenu();
    const topicMenu = useFloatMenu();

    const onCopyLink = () => {
        const filters = { ids: selectedItems };

        generateInsightTopicFilteredLink(
            selectedWorkspace!.id,
            selectedProject!.id,
            filters
        );

        toast.success(intl.formatMessage({ id: 'link_copied_to_clipboard' }));
    };

    const onRemoveInsight = async () => {
        handleLoading(true);

        let error: boolean | null = null;
        for (const item of selectedItems) {
            const [deleteError] = await commentsServices.deleteComment(item);
            if (deleteError) {
                error = deleteError;
                break;
            }
        }

        handleLoading(false);

        if (error) {
            toast.error(intl.formatMessage({ id: 'general_error' }));
            return;
        }

        toast.success(
            intl.formatMessage({ id: 'insight_removed_successfully' })
        );
        deleteMenu.handleMenu();
        onUpdate();
    };

    const onAssignTopic = async (topicId: string) => {
        handleLoading(true);

        const [error, response] = await commentsServices.assignTopicToComments({
            ids: selectedItems,
            topic_id: topicId,
        });

        handleLoading(false);

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            return;
        }

        toast.success(
            intl.formatMessage({ id: 'insights_updated_successfully' })
        );

        getTopics();
        onUpdate();
    };

    const { openedFolders, openFolder, resetOpenedFolders } = useOpenFolders();

    return (
        <div
            className={
                styles.cgInsightsTableHeader +
                ' animate__animated animate__fadeIn'
            }
        >
            {deleteMenu.showMenu && (
                <DeleteConfirmationDialog
                    handleClose={deleteMenu.handleMenu}
                    callback={onRemoveInsight}
                    buttonText={intl.formatMessage({ id: 'delete' })}
                    text={intl.formatMessage({
                        id: 'this_action_cannot_be_undone',
                    })}
                    title={intl.formatMessage({ id: 'delete_insight' }) + '?'}
                    loading={loading}
                />
            )}

            {topicMenu.showMenu && (
                <BaseModal
                    showCloseIcon
                    handleClose={topicMenu.handleMenu}
                    noPadding
                >
                    <FolderSelector
                        structure={filledFolderTree}
                        onConfirm={(topicId) => {
                            onAssignTopic(topicId);
                            resetOpenedFolders();
                            topicMenu.handleMenu();
                        }}
                        onCancel={topicMenu.handleMenu}
                        expandedFolders={openedFolders}
                        onExpandFolder={openFolder}
                        placeholder={intl.formatMessage({
                            id: 'search_folders_and_topics',
                        })}
                        confirmButtonText={intl.formatMessage({ id: 'save' })}
                        showFiles
                        showUncategorizedFolder
                    />
                </BaseModal>
            )}

            {selectedItems.length > 0 && (
                <span className={styles.cgInsightsTableHeader__selection}>
                    {selectedItems.length}{' '}
                    {intl.formatMessage({ id: 'selected' })}
                </span>
            )}

            {selectedAllVisible && (
                <Button
                    onClick={() => handleSelection(nonPaginatedIds!)}
                    variant="secondary"
                    disabled={loading}
                >
                    <span>
                        {intl.formatMessage({ id: 'select_all' })} (
                        {nonPaginatedIds!.length})
                    </span>
                </Button>
            )}

            <Button variant="secondary" onClick={() => handleSelection([])}>
                {intl.formatMessage({ id: 'deselect' })}
            </Button>

            {!showActions && (
                <div className={styles.cgInsightsTableHeader__actions}>
                    <RestrictedUI
                        to={PERMISSIONS.EDIT}
                        entity={ENTITIES.INSIGHTS}
                    >
                        <Button
                            onClick={topicMenu.handleMenu}
                            variant="secondary"
                            iconBefore={CaretBoxIcon}
                            disabled={loading}
                        >
                            <span>
                                {intl.formatMessage({
                                    id: 'assign_topic',
                                })}
                            </span>
                        </Button>
                    </RestrictedUI>

                    <Button
                        variant="secondary"
                        iconBefore={Link}
                        onClick={onCopyLink}
                    >
                        <span>
                            {intl.formatMessage({
                                id: 'copy_link_to_selection',
                            })}
                        </span>
                    </Button>

                    <TagInsightsButton
                        selectedItems={selectedItems}
                        insights={insights}
                        disabled={loading}
                        nonPaginatedDocumentIds={nonPaginatedDocumentIds!}
                    />

                    <Button
                        variant="secondary"
                        onClick={onDownload}
                        iconBefore={Download}
                        disabled={loading}
                    >
                        {intl.formatMessage({ id: 'download' })} .xlsx
                    </Button>

                    <RestrictedUI
                        to={PERMISSIONS.EDIT}
                        entity={ENTITIES.INSIGHTS}
                    >
                        <IconButton
                            onClick={deleteMenu.handleMenu}
                            variant="secondary"
                            icon={Trash2}
                            disabled={loading}
                        />
                    </RestrictedUI>
                </div>
            )}
        </div>
    );
};

export default InsightsTableHeader;
