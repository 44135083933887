import {
    BaseFloatMenu,
    FolderSelector,
    TextInput,
} from '~/components/UIElements';
import { useRef, useState } from 'react';
import { useEntityContext, useFloatMenu, useOpenFolders } from '~/hooks';
import { TopicsProviderInterface } from '~/modules/Topics/types/TopicsContext.interface';
import { TopicsContext } from '~/context/topics';
import { Popover } from 'react-tiny-popover';
import { TopicInterface } from '../../types/Topic.interface';
import { useIntl } from 'react-intl';
import CreateUpdateTopicForm from '~/modules/Topics/components/CreateUpdateTopicForm';
import SelectorItemPill from '~/components/UIElements/FolderSelectorInput/SelectorItemPIll';
import './TopicsSelectorMenu.scss';

const mainClass = 'cg-topic-selector-menu';

interface TopicSelectorMenuProps {
    selectedTopic: TopicInterface | null;
    onSelectTopic: (topic: TopicInterface | null) => void;
    error?: string;
    placeholder?: string;
    label?: string;
    isOptional?: boolean;
    canCreate?: boolean;
    topicMenuSize?: 'normal' | 'small';
}

const TopicSelectorMenu: React.FC<TopicSelectorMenuProps> = ({
    selectedTopic,
    onSelectTopic,
    error,
    placeholder,
    label,
    isOptional = false,
    canCreate,
    topicMenuSize = 'normal',
}) => {
    const intl = useIntl();

    const selectorMenu = useFloatMenu();

    const { topics, filledFolderTree, getTopicColor } =
        useEntityContext<TopicsProviderInterface>(TopicsContext);
    const { openedFolders, openFolder } = useOpenFolders();

    const [step, setStep] = useState(0);

    const [topicName, setTopicName] = useState('');

    const handleSelectTopic = (topicId: string) => {
        selectorMenu.setShowMenu(false);
        const topicOb = topics.find((t) => t.id === topicId);
        if (!topicOb) return;

        setTopicName('');

        onSelectTopic(topicOb);
    };

    const onKeyDown = (e) => {
        if (selectedTopic && !topicName && e.key === 'Backspace') {
            onSelectTopic(null);
        }
    };

    const onCreateTopic = (newTopic: TopicInterface) => {
        setStep(0);
        onSelectTopic(newTopic);
        setTopicName('');
        selectorMenu.setShowMenu(false);
    };

    const getMenu = () => {
        if (step === 0) {
            return (
                <FolderSelector
                    structure={filledFolderTree}
                    value={selectedTopic?.id}
                    onConfirm={handleSelectTopic}
                    onCancel={() => selectorMenu.setShowMenu(false)}
                    expandedFolders={openedFolders}
                    onExpandFolder={openFolder}
                    placeholder={intl.formatMessage({
                        id: 'search_folders_and_topics',
                    })}
                    confirmButtonText={intl.formatMessage({
                        id: 'select',
                    })}
                    showFiles
                    showUncategorizedFolder
                    onSelectNewItem={canCreate ? () => setStep(1) : undefined}
                    showSearchBar={false}
                    customSearchValue={topicName}
                    loading={false}
                    size="small"
                    selectDirectly
                />
            );
        } else {
            return (
                <CreateUpdateTopicForm
                    callback={onCreateTopic}
                    initialValues={{
                        name: topicName,
                    }}
                    customConfirmationText={intl.formatMessage({
                        id: 'create_and_add_topic',
                    })}
                    size={topicMenuSize}
                />
            );
        }
    };

    const container = useRef();

    return (
        <div className={mainClass} ref={container}>
            <label>
                {label ||
                    intl.formatMessage({
                        id: 'select_or_create_topic',
                    })}

                {isOptional && (
                    <small>{intl.formatMessage({ id: 'optional' })}</small>
                )}
            </label>
            <Popover
                isOpen={selectorMenu.showMenu}
                positions={['bottom', 'left', 'right']}
                align="start"
                onClickOutside={() => {
                    selectorMenu.setShowMenu(false);
                    setStep(0);
                }}
                parentElement={container.current}
                content={
                    <BaseFloatMenu
                        animated={false}
                        className={`${mainClass}__popup-${step}`}
                    >
                        {getMenu()}
                    </BaseFloatMenu>
                }
            >
                <div
                    className={`${mainClass}__input ${mainClass}__${selectedTopic ? 'input-selected' : ''}`}
                >
                    {selectedTopic && (
                        <SelectorItemPill
                            label={selectedTopic.name}
                            onRemove={() => onSelectTopic(null)}
                            variant={getTopicColor(selectedTopic)}
                        />
                    )}
                    <TextInput
                        error={error}
                        value={topicName}
                        onChange={(e) => setTopicName(e.target.value)}
                        onKeyDown={onKeyDown}
                        onClick={() => selectorMenu.setShowMenu(true)}
                        disabled={step === 1}
                        placeholder={placeholder || ''}
                    />
                </div>
            </Popover>
            {error && <span className={mainClass + '__error'}>{error}</span>}
        </div>
    );
};

export default TopicSelectorMenu;
