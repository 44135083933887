import {
    InsightInterface,
    InsightInterfaceDetailed,
} from '~/interfaces/entities';

export const formatHighlightAreas = (
    insights: (InsightInterfaceDetailed | InsightInterface)[]
) => {
    for (const insight of insights) {
        insight.highlight_areas = insight.highlight_areas?.map((highlight) => ({
            ...highlight,
            pageIndex: highlight.page,
        }));
    }
};
