import { BaseTable } from '~/components/Tables';
import { InsightInterfaceDetailed } from '~/interfaces/entities';
import { PagingValues } from '~/hooks/useTable';
import styles from './InsightsTable.module.scss';
import InsightsTableHeader from '~/modules/Insights/components/InsightsTable/InsightsTableHeader';

interface InsightsTableProps {
    insights: InsightInterfaceDetailed[];
    columns: any[];
    paging: Pick<
        PagingValues,
        | 'onSelectItem'
        | 'setSelectedItems'
        | 'selectedItems'
        | 'totalPages'
        | 'pageNumber'
        | 'totalItems'
    >;
    loading: boolean;
    reorderableColumns: boolean;
    tableHeight?: number;
    lite?: boolean;
    nonPaginatedIds?: string[];
    nonPaginatedDocumentIds?: string[];
    setLoading: (loading: boolean) => void;
    handleColumnsOrder?: (columnToMoveId: string, newPosition: number) => void;
    onDownload: () => void;
    onAddNewColumn: () => void;
    onUpdateCallback: () => void;
    onScrollTouchesBottom: () => void;
    onSelectAll: () => void;
}

const InsightsTable = ({
    insights,
    columns,
    paging,
    loading,
    tableHeight,
    lite,
    reorderableColumns,
    nonPaginatedIds,
    nonPaginatedDocumentIds,
    setLoading,
    handleColumnsOrder,
    onDownload,
    onScrollTouchesBottom,
    onAddNewColumn,
    onUpdateCallback,
}: InsightsTableProps) => {
    const onScrollToBottom = () => {
        const { pageNumber, totalPages } = paging;

        if (pageNumber + 1 === totalPages) return;

        onScrollTouchesBottom();
    };

    const showHeader = paging.selectedItems.length > 0;

    return (
        <div
            className={
                styles.cgInsightsTable + ' animate__animated animate__fadeIn'
            }
        >
            {showHeader && (
                <InsightsTableHeader
                    insights={insights}
                    nonPaginatedIds={nonPaginatedIds}
                    nonPaginatedDocumentIds={nonPaginatedDocumentIds}
                    onDownload={onDownload}
                    showActions={Boolean(lite)}
                    handleLoading={setLoading}
                    onUpdate={onUpdateCallback}
                    selectedItems={paging.selectedItems}
                    handleSelection={paging.onSelectItem}
                    loading={loading}
                />
            )}

            <BaseTable
                data={insights}
                columns={columns}
                loading={loading}
                onSelectionChange={paging.setSelectedItems}
                selectedItems={paging.selectedItems}
                onScrollToBottom={onScrollToBottom}
                onAddNewColumn={onAddNewColumn}
                tableHeight={tableHeight}
                handleColumnsOrder={handleColumnsOrder}
                reorderableColumns={reorderableColumns}
                minWidth={900}
            />
        </div>
    );
};

export default InsightsTable;
