import { useContext, useRef, useEffect, useState, useMemo } from 'react';
import { InsightsTable } from '~/components/Insights';
import { TopicsContext } from '~/context/topics';
import {
    useColumnsFilterContext,
    useDynamicInsightColumns,
    useEntityContext,
    useFlexTableHeight,
    useTable,
} from '~/hooks';
import { commentsServices } from '~/services';
import parseColumnValues from '~/utils/parseColumnValues';
import './index.scss';
import { formatHighlightAreas } from '../../utils/formatHighlightAreas';
import FIXED_COLUMN_NAMES from '../../constants/insightTableColumns';
import replaceLocationState from '../../utils/replaceLocationState';
import PermissionContext from '../../context/permissions/PermissionContext';
import { PERMISSIONS } from '../../constants/memberPermissions';
import { ENTITIES } from '../../constants/entities';
import { TagsContext } from '../../context/tags';
import {
    extractFilters,
    buildSortingParameters,
} from '../../helpers/insightsDataHandlers';
import {
    AuthContextInterface,
    ImageViewerProviderInterface,
    InsightsResultsView,
    ProjectProviderInterface,
} from '~/interfaces/contexts';
import { TagsProviderInterface } from '~/modules/Tags/types/TagsContext.interface';
import {
    DynamicPropertyInterfaceDetailed,
    InsightInterfaceDetailed,
} from '~/interfaces/entities';
import InsightsSubheader from '~/pages/Insights/InsightsSubheader';
import { ImageViewerContext } from '~/context/imageViewer';
import analytics, { EVENT_NAMES_ENUM } from '~/helpers/analytics';
import { ProjectContext } from '~/context/project';
import { TopicsProviderInterface } from '~/modules/Topics/types/TopicsContext.interface';
import { AuthContext } from '~/context/auth';
import useWorkspaces from '~/modules/Workspaces/hooks/useWorkspaces';
import getRawColumnValues from '~/helpers/getRawColumnValues';

const bufferDownloadTool = () => import('../../utils/downloadBuffer');

const commentBuilder = () =>
    import('../../helpers/ExcelUtils/buildCommentsExcel');

const InsightsResults = ({ handleView }: { handleView: () => void }) => {
    // this flag is necessary to avoid multiple requests when useHook dependencies changes simultaneously
    const isFirstRender = useRef(true);
    const searchFromQueryLink = window.history.state?.usr;

    const { selectedWorkspace } = useWorkspaces();

    // this state stores the columns metadata (name, type, position, id) and other properties
    const [columnsMetadata, setColumnsMetadata] = useState<
        DynamicPropertyInterfaceDetailed[]
    >([]);

    // filters by column
    const {
        columnValues,
        columnFilters,
        sortParams,
        filterParams,
        handleColumnValues,
        resetFilterColumn,
        onApplyFilter,
        resetAllFilters,
    } = useColumnsFilterContext(columnsMetadata); //custom hook

    const { topics, topicsLoaded, filledFolderTree, getTopics } =
        useEntityContext<TopicsProviderInterface>(TopicsContext);

    const { user } = useEntityContext<AuthContextInterface>(AuthContext);

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const { handleImage } =
        useEntityContext<ImageViewerProviderInterface>(ImageViewerContext);

    const { isAllowedTo } = useContext(PermissionContext);

    const { tags, tagsLoaded } =
        useEntityContext<TagsProviderInterface>(TagsContext);

    const canEdit = useMemo(
        () => isAllowedTo(ENTITIES.INSIGHTS, PERMISSIONS.EDIT),
        [isAllowedTo]
    );

    const {
        pageNumber,
        pageSize,
        totalPages,
        selectedItems,
        totalItems,
        onSelectItem,
        setTotalItems,
        onPageChange,
        setSelectedItems,
    } = useTable({ multipleSelect: true });

    const [loading, setLoading] = useState(false);
    const [loadingScreenshots, setLoadingScreenshots] = useState(false);
    const [insights, setInsights] = useState<InsightInterfaceDetailed[]>([]);

    // when user comes from a query link, column filters are applied to display the queried insights. They are represented by the col filter 'ids'
    const previewMode = useMemo(
        () => columnFilters.some((c) => c.column === 'ids'),
        [columnFilters]
    );

    // check if the user is coming from a query link;
    useEffect(() => {
        // when the user leaves the page, we need to remove the query params from the state
        return () => {
            replaceLocationState();
        };
    }, []);

    // detect when user applies filters (query links also simulate this action)
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        getInsights(true);
    }, [columnFilters, sortParams]);

    useEffect(() => {
        if (!topicsLoaded || !tagsLoaded) return;

        if (searchFromQueryLink) return handleQueryLink(searchFromQueryLink);
        getInsights(true, undefined, false);
    }, [topics, tags]);

    const resetMainStates = () => {
        insights.length && setInsights([]);
        selectedItems.length && setSelectedItems([]);
        columnsMetadata.length && setColumnsMetadata([]);
    };

    useEffect(() => {
        // if it is not the first render and still there is no selectedProject. It means the workspace doesn't contain any existing project yet.
        // So we have to reset all
        if (!selectedProject) {
            if (!isFirstRender.current || columnsMetadata.length)
                resetMainStates();
            return;
        }

        // if when user changed the preject the preview mode was active, remove the query params from the state
        if (previewMode) exitPreviewMode();

        getTableColumns();
    }, [selectedProject?.id]);

    // detect when infinite scrolling hook changes the page
    useEffect(() => {
        if (pageNumber === 0) return;

        getMoreResults();
    }, [pageNumber]);

    //------- DYNAMIC TABLE STARTS ----------

    const getTableColumns = async () => {
        setLoading(true);
        const [error, response] = await commentsServices.getCommentColumns(
            selectedProject?.id as string
        );
        setLoading(false);

        if (error) return;

        const { data } = response;
        setColumnsMetadata(data);
    };

    const { orderedColumns, tableColumns, onAddNewColumn, handleColumnsOrder } =
        useDynamicInsightColumns({
            handleUpdateCell: setInsights,
            selectedProjectId: selectedProject?.id as string,
            setLoading,
            FIXED_COLUMN_NAMES,
            results: insights,
            filtering: {
                columnValues,
                sortParams,
                resetFilterColumn,
                columnFilters,
                onApplyFilter,
            },
            handleViewImage: handleImage,
            folderStructure: filledFolderTree,
            loadingScreenshots,
            onUpdateTopic: () => {
                getInsights(true);
                getTopics();
            },
            onUpdateCellCallback: () => getColumnValues(),
            onUpdateItems: () => getInsights(true),
            loading,
            columnsMetadata,
            setColumnsMetadata,
            getTableColumns,
            allowEdit: canEdit,
        });
    //------- DYNAMIC TABLE ENDS ----------

    const exitPreviewMode = () => {
        resetAllFilters();
        replaceLocationState();
    };

    const handleQueryLink = (queryPayload = {}) => {
        // simulate applying a column filter
        const { dataFilter } = queryPayload;

        exitPreviewMode();

        if (dataFilter?.ids?.length) {
            // ids are a subset of insight ids to be displayed
            onApplyFilter({
                column: 'ids',
                values: [
                    {
                        label: 'ids',
                        value: dataFilter.ids.join('&'),
                        __id: 'ids',
                    },
                ],
                isDynamicColumnFilter: false,
            });
            return;
        }
    };

    const getInsights = async (
        isFiltered: boolean,
        queryFilters = {},
        resetSelection = true
    ) => {
        if (!selectedProject) return;

        if (resetSelection) onSelectItem('');

        getColumnValues();

        setLoading(true);
        const filtersPayload = isFiltered
            ? extractFilters(filterParams, columnsMetadata, columnValues)
            : {};

        const options = {
            ...filtersPayload,
            page: 0,
            page_size: pageSize,
            project_ids: selectedProject?.id,
            ...queryFilters,
            ...buildSortingParameters(sortParams),
        };

        const [error, response] = await commentsServices.getComments(options);

        if (error) {
            setLoading(false);
            return;
        }

        const { data, paging } = response;
        setLoading(false);
        setInsights(data);
        setTotalItems(paging.total);
        // if the user is coming from a query link, then we need to remove the query params from the state
        if (searchFromQueryLink) replaceLocationState();

        if (pageNumber !== 0) onPageChange(0);

        setLoadingScreenshots(true);
        const modifiedData =
            await commentsServices.appendImagesToInsights(data);

        setInsights(modifiedData);

        setLoadingScreenshots(false);
    };

    const getMoreResults = async () => {
        setLoading(true);

        const filtersPayload = extractFilters(
            filterParams,
            columnsMetadata,
            columnValues
        );

        const options = {
            page: pageNumber,
            page_size: pageSize,
            ...filtersPayload,
            project_ids: selectedProject?.id,
            ...buildSortingParameters(sortParams),
        };

        const [error, response] = await commentsServices.getComments(options);

        if (error || !response.data.length) {
            setLoading(false);
            return;
        }
        const { data } = response;
        // get images if insight is a screenshot
        const modifiedData =
            await commentsServices.appendImagesToInsights(data);

        setLoading(false);
        setInsights([...insights, ...modifiedData]);
    };

    const getColumnValues = async (queryFilters = {}) => {
        const filtersPayload = extractFilters(
            filterParams,
            columnsMetadata,
            columnValues
        );

        const [error, response] = await commentsServices.getColumnValues({
            // if filters are applied by a query link, then set all column values
            ...filtersPayload,
            ...queryFilters,
            project_ids: selectedProject?.id,
        });

        if (!error) {
            handleColumnValues(parseColumnValues(response.data));
        }
    };

    const nonPaginatedIds = getRawColumnValues(columnValues, 'id');
    const nonPaginatedDocumentIds = getRawColumnValues(
        columnValues,
        'document_name'
    );

    const onDownloadSelection = async () => {
        analytics.trackGenericEvent(EVENT_NAMES_ENUM.EXPORT_EXCEL);

        let insightsToDownload: InsightInterfaceDetailed[];

        // there are some cases when not all the insights are visible in the table. So we need to get them all first
        const notAllAreVisible = selectedItems.length > insights.length;

        if (notAllAreVisible) {
            setLoading(true);

            const filtersPayload = extractFilters(
                filterParams,
                columnsMetadata
            );

            const options = {
                ...filtersPayload,
                project_ids: selectedProject?.id,
                all: true,
                ...buildSortingParameters(sortParams),
            };

            const [error, response] =
                await commentsServices.getComments(options);
            setLoading(false);

            if (error) return;

            insightsToDownload = await commentsServices.appendImagesToInsights(
                response.data
            );
        } else {
            insightsToDownload = insights.filter((i) =>
                selectedItems.includes(i.id)
            );
        }

        const userName = user?.first_name + ' ' + user?.last_name;

        formatHighlightAreas(insightsToDownload);

        const { buildCommentsExcel } = await commentBuilder();

        const excel = await buildCommentsExcel({
            filter: {
                dataFilter: columnFilters,
                project: selectedProject?.id as string,
                workspace: selectedWorkspace?.id as string,
            },
            userName,
            result: insightsToDownload,
            orderedColumns,
            folderStructure: filledFolderTree,
        });

        if (excel) {
            const { downloadBuffer } = await bufferDownloadTool();
            downloadBuffer(excel.buffer, excel.fileName);
        }
    };

    const tableHeight = useFlexTableHeight({
        containerClassName: 'cg-insights',
        offset: 75 + (selectedItems.length ? 55 : 0),
    });

    return (
        <>
            <InsightsSubheader
                view={InsightsResultsView.INSIGHTS}
                previewMode={previewMode}
                handleView={handleView}
                handlePreviewMode={exitPreviewMode}
                loading={loading}
                showActions={false}
            />

            <InsightsTable
                insights={insights}
                columns={tableColumns}
                paging={{
                    onSelectItem,
                    setSelectedItems,
                    totalItems,
                    selectedItems,
                    totalPages,
                    pageNumber,
                }}
                onScrollTouchesBottom={() =>
                    !loading && onPageChange(pageNumber + 1)
                }
                loading={loading}
                setLoading={setLoading}
                onUpdateCallback={() => {
                    getInsights(true);
                    getTopics();
                }}
                onDownload={onDownloadSelection}
                onAddNewColumn={(c) => {
                    onAddNewColumn(c);
                    getColumnValues();
                }}
                handleColumnsOrder={handleColumnsOrder}
                reorderableColumns={canEdit}
                tableHeight={tableHeight}
                nonPaginatedIds={nonPaginatedIds}
                nonPaginatedDocumentIds={nonPaginatedDocumentIds}
            />
        </>
    );
};

export default InsightsResults;
